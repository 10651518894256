import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, Snackbar, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { printConsultantResume } from '../actions/projectPrint';
import { GeneralDialog } from '../components/Dialog';
import PdfTemplate from '../components/PdfTemplate';
import Layout from '../layout/HomeLayout';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    pdfRoot: {
      minHeight: '1092px',
      width: '1028px',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      margin: '1rem auto',
      position: 'relative',
    },
    button: {
      position: 'absolute',
      right: '1rem',
      top: '1rem',
    },
  });

const PdfPrint = ({ classes }: Props): React.ReactElement => {
  debug('Loading index page');
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [printOpen, setPrintOpen] = React.useState(false);
  const copyToClipboard = async () => {
    const url = window.location.href;
    try {
      if (navigator.clipboard) {
        await window.navigator.clipboard.writeText(url);
        setShareMessage(`已复制链接`);
        setOpen(true);
      } else {
        const el = document.createElement('textarea');
        el.value = url;

        document.body.appendChild(el);
        el.focus();
        el.select();

        try {
          const successful = document.execCommand('copy');
          successful ? setShareMessage(`已复制链接`) : setShareMessage(`无法复制链接！`);
          setOpen(true);
        } catch (err) {
          setShareMessage(`无法复制链接！`);
          setOpen(true);
        } finally {
          document.body.removeChild(el);
        }
      }
    } catch (err) {
      setShareMessage(`复制链接失败`);
      setOpen(true);
    }
  };

  return (
    <div>
      <Layout>
        <div className={classes.pdfRoot}>
          <div>
            <PdfTemplate />
          </div>
          <Snackbar
            style={{ zIndex: 10000 }}
            open={open}
            autoHideDuration={5000}
            onClose={() => {
              setOpen(false);
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="success"
            >
              {shareMessage}
            </Alert>
          </Snackbar>
          <GeneralDialog
            isOpen={printOpen}
            titleText="提示"
            contentText="是否下载并导出简历pdf文件？"
            handleEdit={() => {
              if (typeof location !== 'undefined') {
                const consultantId = parseInt(location.hash.replace('#', ''));
                consultantId && dispatch(printConsultantResume(consultantId));
                setPrintOpen(false);
              }
            }}
            handleDialogClose={() => {
              setPrintOpen(false);
            }}
          ></GeneralDialog>
          <div className={classes.button}>
            <button
              onClick={() => {
                setPrintOpen(true);
              }}
            >
              导出
            </button>
            <button
              onClick={() => {
                copyToClipboard();
              }}
            >
              分享
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(PdfPrint);
